import React from 'react';
import { Box, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { TreeDevice } from 'src/types';
import {
  checkDeviceNameValid,
  getDeviceChildrenById,
  log,
} from 'src/utils/helpers';
import { BsCameraVideo, BsQuestionLg } from 'react-icons/bs';
import { VscCircuitBoard, VscTools } from 'react-icons/vsc';
import { FaNetworkWired } from 'react-icons/fa';
import { GiTRexSkull } from 'react-icons/gi';
import { MdOutlineSensors } from 'react-icons/md';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { TiCalculator } from 'react-icons/ti';
import { IconContext } from 'react-icons/lib';
import { deviceNameChanged, setChecked } from 'src/features/isc/actions/thunks';
import ExpandableDevice from 'src/features/isc/ExpandableDevice/ExpandableDevice';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  setChildExpanded,
  setParentExpanded,
  setSubchildExpanded,
} from 'src/stores/slices/isc/devicesSlice';
import { selectGlobalIsLoading } from 'src/stores/slices/userSlice';
import { selectDarkMode } from 'src/stores/slices/userPrefsSlice';
import SearchByDeviceNameFilter from '@features/isc/tree/components/filters/SearchByDeviceNameFilter';
import { TreeLevel } from '@features/isc/tree/constants';

const iconSize = '1.2em';

export interface DeviceErrorPatternsProp {
  devicePatterns: string[];
  errorText: string;
}

function TreeDeviceIcon(props: { device: TreeDevice }): React.ReactElement {
  const darkMode = useAppSelector(selectDarkMode);
  let deviceImage: React.ReactNode;
  const device = props.device;

  const alt = device.device_type_name_special
    ? device.device_type_name_special.replace('_', ' ')
    : 'invalid device';

  const normalColor = darkMode ? 'white' : '#0972d3';
  const warnColor = darkMode ? 'red' : 'red';
  const iconStyle = {
    color: normalColor,
    className: 'inline',
    size: iconSize,
  };

  if (device.invalidName) {
    iconStyle.color = warnColor;
  }

  switch (device.device_type_name_special) {
    case 'parent_device':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'parent'}>
          <FaNetworkWired />
        </IconContext.Provider>
      );
      break;
    case 'camera':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'cam'}>
          <BsCameraVideo />
        </IconContext.Provider>
      );
      break;
    case 'card_reader':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'cr'}>
          <TiCalculator />
        </IconContext.Provider>
      );
      break;
    case 'card_reader_rex':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'crr'}>
          <GiTRexSkull />
        </IconContext.Provider>
      );
      break;
    case 'account_input':
    case 'card_reader_aux_input_1':
    case 'card_reader_aux_input_2':
    case 'alarm_panel_input':
    case 'intrusion_panel_input':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'ipi'}>
          <MdOutlineSensors />
        </IconContext.Provider>
      );
      break;

    case 'card_reader_door_contact':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'crdc'}>
          <VscTools />
        </IconContext.Provider>
      );
      break;
    case 'card_reader_aux_output_1':
    case 'card_reader_aux_output_2':
    case 'intrusion_panel_output_onboard':
    case 'alarm_panel_output':
    case 'intrusion_panel_output':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'ipo'}>
          <RiAlarmWarningLine />
        </IconContext.Provider>
      );
      break;
    case 'alarm_panel':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'ap'}>
          <VscCircuitBoard />
        </IconContext.Provider>
      );
      break;

    default:
      log(`No icon found for device type: ${alt}: ${device.device_name}`);
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'q'}>
          <BsQuestionLg />
        </IconContext.Provider>
      );
      break;
  }

  return <span className="stDeviceImageParent">{deviceImage}</span>;
}

function getDeviceName(device: TreeDevice): string {
  let deviceName = device.device_name;
  if (!deviceName) {
    const nameNotFound = 'No Name for Device';
    if (device.treeLevel === TreeLevel.PARENT) {
      deviceName = device.parent_device_name ?? nameNotFound;
    } else if (device.treeLevel === TreeLevel.CHILD) {
      deviceName = device.child_device_name ?? nameNotFound;
    } else {
      deviceName = device.subchild_device_name ?? nameNotFound;
    }
  }
  return deviceName;
}

function getDeviceErrorPatterns(device: TreeDevice): DeviceErrorPatternsProp {
  let errorText = '';
  let devicePatterns: string[] = [];
  if (!!device.errorText && device.errorText != '') {
    errorText = device.errorText;
  } else {
    devicePatterns = checkDeviceNameValid(device);
    if (devicePatterns.length) {
      errorText = 'Invalid Device Name';
    }
  }
  return { errorText, devicePatterns };
}

export function SubChild(props: { subchild: TreeDevice }): React.ReactElement {
  const dispatch = useAppDispatch();
  const { errorText, devicePatterns } = getDeviceErrorPatterns(props.subchild);

  return (
    <ExpandableDevice
      key={`sc${props.subchild.deviceKey}`}
      deviceIcon={<TreeDeviceIcon device={props.subchild} />}
      deviceName={getDeviceName(props.subchild)}
      iconSize={iconSize}
      loading={props.subchild.loading}
      className={'statusTreeItem statusTreeChild2'}
      expanded={props.subchild.expanded}
      checked={props.subchild.checked}
      highlight={props.subchild.highlight}
      errorText={errorText}
      devicePatterns={devicePatterns}
      onChangeExpanded={(change): void => {
        log('Change detail', false, { change: change });
        dispatch(
          setSubchildExpanded({
            device: props.subchild,
            expanded: change.expanded,
          })
        );
      }}
      onCheckChanged={(change): void => {
        dispatch(
          setChecked({ device: props.subchild, checked: change.checked })
        );
      }}
      onDeviceNameChanged={(change): void => {
        dispatch(
          deviceNameChanged({ device: props.subchild, newName: change.newName })
        );
      }}
    />
  );
}

export function Child(props: { child: TreeDevice }): React.ReactElement {
  const dispatch = useAppDispatch();
  const subchildDevices = useAppSelector(
    state => state.deviceState.subchildDevices
  );
  const children = getDeviceChildrenById(props.child, subchildDevices);
  // render element only if children exist. no container icon otherwise
  const renderedChildren =
    children.length &&
    children.map(device => {
      return (
        <SubChild
          key={`${device.deviceKey}_${device.device_name}_subchild`}
          subchild={device}
        />
      );
    });
  const { errorText, devicePatterns } = getDeviceErrorPatterns(props.child);

  return (
    <ExpandableDevice
      key={`child${props.child.deviceKey}`}
      /* eslint-disable */
      children={renderedChildren}
      deviceIcon={<TreeDeviceIcon device={props.child} />}
      deviceName={getDeviceName(props.child)}
      iconSize={iconSize}
      loading={props.child.loading}
      className={'statusTreeItem statusTreeChild1'}
      expanded={props.child.expanded}
      checked={props.child.checked}
      highlight={props.child.highlight}
      errorText={errorText}
      devicePatterns={devicePatterns}
      onChangeExpanded={(change): void => {
        log('Change detail', false, { change: change });
        dispatch(
          setChildExpanded({ device: props.child, expanded: change.expanded })
        );
      }}
      onCheckChanged={(change): void => {
        dispatch(setChecked({ device: props.child, checked: change.checked }));
      }}
      onDeviceNameChanged={(change): void => {
        dispatch(
          deviceNameChanged({ device: props.child, newName: change.newName })
        );
      }}
    />
  );
}

export function Isc(props: { device: TreeDevice }): React.ReactElement {
  const dispatch = useAppDispatch();
  const childDevices = useAppSelector(state => state.deviceState.childDevices);
  const children = getDeviceChildrenById(props.device, childDevices);
  // render element only if children exist. no container icon otherwise
  const renderedChildren =
    children.length &&
    children.map(device => {
      return <Child key={`${device.deviceKey}_child`} child={device} />;
    });
  const { errorText, devicePatterns } = getDeviceErrorPatterns(props.device);

  return (
    <ExpandableDevice
      key={`isc${props.device.deviceKey}`}
      children={renderedChildren}
      deviceIcon={<TreeDeviceIcon device={props.device} />}
      deviceName={getDeviceName(props.device)}
      iconSize={iconSize}
      loading={props.device.loading}
      className={'statusTreeItem statusTreeChild0'}
      expanded={props.device.expanded}
      checked={props.device.checked}
      highlight={props.device.highlight}
      errorText={errorText}
      devicePatterns={devicePatterns}
      badgeText={props.device.DeviceSource.toLocaleUpperCase()}
      badgeColor={
        props.device.DeviceSource.toLowerCase() == 'onguard' ? 'blue' : 'green'
      }
      onChangeExpanded={(change): void => {
        dispatch(
          setParentExpanded({ device: props.device, expanded: change.expanded })
        );
      }}
      onCheckChanged={(change): void => {
        dispatch(setChecked({ device: props.device, checked: change.checked }));
      }}
      onDeviceNameChanged={(change): void => {
        dispatch(
          deviceNameChanged({ device: props.device, newName: change.newName })
        );
      }}
    />
  );
}

export function DeviceTree(): React.ReactElement {
  const parentDevices = useAppSelector(
    state => state.deviceState.parentDevices
  );

  const globalIsLoading = useAppSelector(selectGlobalIsLoading);

  const renderedIscs = parentDevices?.map(isc => {
    return <Isc key={`${isc.deviceKey}_isc`} device={isc} />;
  });

  return (
    <Box textAlign="center" color="inherit" key={'deviceTree'}>
      {globalIsLoading && (
        <SpaceBetween size={'l'}>
          <Spinner size={'large'} />
          <b>Loading Devices...</b>
        </SpaceBetween>
      )}
      {!globalIsLoading && !parentDevices?.length && (
        <h4>No Devices Returned</h4>
      )}
      {!globalIsLoading && !!parentDevices?.length && (
        <SpaceBetween size={'l'}>
          <SearchByDeviceNameFilter />
          <Box>{renderedIscs}</Box>
        </SpaceBetween>
      )}
    </Box>
  );
}
