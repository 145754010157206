import { useMutation } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  BulkMilestoneRenameResponse,
  FixDeviceIpsMutation,
  FixDeviceIpsMutationVariables,
} from 'src/API';
import { fixDeviceIps } from 'src/graphql/mutations';

const apiFixDeviceIps = async (
  variables: FixDeviceIpsMutationVariables
): Promise<BulkMilestoneRenameResponse> => {
  const response = (await API.graphql(
    graphqlOperation(fixDeviceIps, variables)
  )) as GraphQLResult<FixDeviceIpsMutation>;

  const fixDeviceIpsResponse = response.data?.fixDeviceIps;
  if (!fixDeviceIpsResponse) {
    throw new Error('Failed to get fixDeviceIps results');
  }

  return fixDeviceIpsResponse;
};

export const useFixDeviceIps = () => {
  const {
    mutate: fixDeviceIpsMutation,
    mutateAsync: fixDeviceIpsAsync,
    data: response,
    isSuccess,
    isError,
    isPending,
  } = useMutation({
    mutationFn: apiFixDeviceIps,
  });

  return {
    fixDeviceIpsMutation,
    fixDeviceIpsAsync,
    response,
    isSuccess,
    isError,
    isPending,
  };
};
