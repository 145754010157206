import { Button } from '@amzn/awsui-components-react';
import { selectAllRecorders } from '@stores/slices/milestones/milestonesSlice';
import * as React from 'react';

import Alert from '@amzn/awsui-components-react/polaris/alert';
import { useFixDeviceIps } from 'src/services/mutations/useFixDeviceIps';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  addErrorMessage,
  removeErrorMessage,
  selectRecorderIdsContainingIpMismatch,
} from 'src/stores/slices/milestones/tree/treeSlice';

interface IpMismatchNotificationsProps {
  refetchDevices: () => void;
}
export const IpMismatchNotifications = ({
  refetchDevices,
}: IpMismatchNotificationsProps) => {
  const dispatch = useAppDispatch();
  const allRecorders = useAppSelector(selectAllRecorders);
  const recorderIdsWithIpMismatch = useAppSelector(
    selectRecorderIdsContainingIpMismatch
  );
  const { fixDeviceIpsAsync, isPending } = useFixDeviceIps();

  async function handleButtonClick() {
    const results = await Promise.all(
      recorderIdsWithIpMismatch.map(recorderId =>
        fixDeviceIpsAsync({ recorderServer: recorderId })
      )
    );
    refetchDevices();
    results
      .flatMap(result => result.devices)
      .filter(device => device?.name && device.name === device.lastName)
      .forEach(device => {
        const errorMessage = `Failed to fix IP for ${device?.name}`;
        dispatch(addErrorMessage(errorMessage));
        setTimeout(() => {
          dispatch(removeErrorMessage(errorMessage));
        }, 30000);
      });
  }

  return (
    <>
      {recorderIdsWithIpMismatch.length > 0 && (
        <Alert
          statusIconAriaLabel="Warning"
          type={'warning'}
          action={
            <Button onClick={handleButtonClick} loading={isPending}>
              Fix Device IPs
            </Button>
          }
          header="Recorders whose device IPs do not match">
          {allRecorders
            .filter(recorder => recorderIdsWithIpMismatch.includes(recorder.id))
            .map(recorder => recorder.name)
            .join(', ')}
        </Alert>
      )}
    </>
  );
};
