/* eslint-disable @typescript-eslint/no-explicit-any */
import { RecorderDevice, RecorderDeviceTuple } from 'src/API';
import { getCameraNameFieldErrors } from 'src/utils/validations/CameraValidator';
import { getDecommissionedRecorderErrorMessage } from 'src/utils/validations/constants/ErrorMessageConstant';
import { getEncoderCameraNameFieldErrors } from 'src/utils/validations/EncoderCameraValidator';
import { getEncoderMetadataNameFieldErrors } from 'src/utils/validations/EncoderMetadataValidator';
import { getEncoderNameFieldErrors } from 'src/utils/validations/EncoderValidator';
import { getRecorderServerNameFieldError } from 'src/utils/validations/RecorderServerValidator';
import { envVariables } from 'src/resources/envVariables';
import { DeviceFromDDV, TreeDevice } from 'src/types';
import {
  Entity,
  NodeTypes,
  Recorder,
} from 'src/features/milestones/types/api-types';
import { TreeLevel } from '@features/isc/tree/constants';

const isProd = envVariables.stage.toLowerCase() === 'prod';

export interface RecorderIdsBySiteCode {
  [siteCode: string]: string[];
}

export function getRecorderIdsBySiteCode(
  recorders: Recorder[]
): RecorderIdsBySiteCode {
  const idsBySite: RecorderIdsBySiteCode = {};
  recorders.forEach(recorder => {
    if (idsBySite[recorder.siteCode]) {
      idsBySite[recorder.siteCode].push(recorder.id);
    } else {
      idsBySite[recorder.siteCode] = [recorder.id];
    }
  });
  return idsBySite;
}

/* eslint-disable no-console */

export function log(object1: any, error = false, object2: any = null): void {
  const args = object2 ? [object1, object2] : [object1];
  if (error) {
    console.error.apply(window, args);
  } else if (isProd) {
    // no console logging in prod
    return;
  } else {
    console.log.apply(window, args);
  }
}

/* eslint-enable no-console */

/* eslint-disable @typescript-eslint/no-unused-vars */

export function getTimeString(timestamp: number): string {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

function removeItem(array: any, search: any): any[] {
  return [...array.slice(0, search.index), ...array.slice(search.index + 1)];
}

function insertItem(array: any, action: any): any[] {
  return [
    ...array.slice(0, action.index),
    action.item,
    ...array.slice(action.index),
  ];
}

export function replaceAt(array: any, index: number, value: any): any {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

/* eslint-enable @typescript-eslint/no-unused-vars */

export function checkDeviceNameValid(
  device: DeviceFromDDV | TreeDevice
): string[] {
  const regexes: RegExp[] = [
    /^UC_.*/gi,
    /^.*FAKE.*/gi,
    /^.*DCMSN_.*/gi,
    /^.*PLU.*/gi,
  ];
  let patterns = [''];

  switch (device.device_type_name_special) {
    case 'parent_device':
      regexes.push(
        /^\(CO\) .{4,6}-A.{2}/g,
        /^\(CO\) .{4,6}-.*VR.*/g,
        /^.*-LL-.*/g,
        /^.*TRB.*/g
      );
      patterns = [
        '(CO) ****-A**',
        '(CO) *****-A**',
        '(CO) ******-A**',
        '(CO) ****-%VR%',
        '(CO) *****-%VR%',
        '(CO) ******-%VR%',
        '%-LL-%',
        '%TRB%',
      ];
      break;
    case 'alarm_panel':
      regexes.push(
        /^.{4,6}-.{2}\..\..{2}\..{2}-(IN|OUT)/g, // First .{2} is .{1-2} in real data
        /^TBR-.{2}\..\..{2}\..{2}-(IN|OUT)/g
      );
      patterns = [
        '****-**.*.**.**-IN',
        '*****-**.*.**.**-IN',
        '******-**.*.**.**-IN',
        '****-**.*.**.**-OUT',
        '*****-**.*.**.**-OUT',
        '******-**.*.**.**-OUT',
        'TBR-**.*.**.**-IN',
        'TBR-**.*.**.**-OUT',
      ];
      break;
    case 'alarm_panel_input':
      regexes.push(
        /^.{4,6}-.{2}\..{2}-(INT|EXT).*F.*/g,
        /^.{4,6}-.{2}\..{2}-B.*/g,
        /^.{4,6}-.{2}\..{2}-GF.*/g
      );
      patterns = [
        '****-**.**-INT%F%',
        '*****-**.**-INT%F%',
        '******-**.**-INT%F%',
        '****-**.**-EXT%F%',
        '*****-**.**-EXT%F%',
        '******-**.**-EXT%F%',
        '****-**.**-B%',
        '*****-**.**-B%',
        '******-**.**-B%',
        '****-**.**-GF%',
        '*****-**.**-GF%',
        '******-**.**-GF%',
      ];
      break;
    case 'alarm_panel_output':
      regexes.push(
        /^.{4,6}-.{2}\..{2}-(INT|EXT).*[FB].*/g,
        /^.{4,6}-.{2}\..{2}-B.*/g,
        /^.{4,6}-.{2}\..{2}-GF.*/g
      );
      patterns = [
        '****-**.**-INT%F%',
        '*****-**.**-INT%F%',
        '******-**.**-INT%F%',
        '****-**.**-EXT%F%',
        '*****-**.**-EXT%F%',
        '******-**.**-EXT%F%',
        '****-**.**-INT%B%',
        '*****-**.**-INT%B%',
        '******-**.**-INT%B%',
        '****-**.**-EXT%B%',
        '*****-**.**-EXT%B%',
        '******-**.**-EXT%B%',
        '****-**.**-B%',
        '*****-**.**-B%',
        '******-**.**-B%',
        '****-**.**-GF%',
        '*****-**.**-GF%',
        '******-**.**-GF%',
      ];
      break;
    case 'alarm_panel_output_elevator': // Did not find sth similar to Alarm Panel Output Elevator in API responses, but it is in Lenel Invalid Device Names Doc. Case name could be wrong, but regex same as card_reader_aux_*
    case 'card_reader_aux_input_1':
    case 'card_reader_aux_input_2':
    case 'card_reader_aux_output_1':
    case 'card_reader_aux_output_2':
      regexes.push(/^.{4,6}-.*/g, /^TBR-.*/g);
      patterns = ['*****-%', '****-%', '******-%'];
      break;
    case 'camera':
      regexes.push(/^.{4,6}-(INT|EXT).*/g, /^TBR-(INT|EXT).*/g);
      patterns = [
        '*****-INT%',
        '****-INT%',
        '******-INT%',
        '*****-EXT%',
        '****-EXT%',
        '******-EXT%',
        'TBR-INT%',
        'TBR-EXT%',
      ];
      break;
    case 'card_reader':
      regexes.push(
        /^.{4,6}-.{2}\.[0-3]\..{2}-(INT|EXT)[1-6] .*[FP].*/g // First .{2} is .{1-2} in real data
      );
      patterns = [
        '****-**.[0-3].**-INT[1-6] %F%',
        '*****-**.[0-3].**-INT[1-6] %F%',
        '******-**.[0-3].**-INT[1-6] %F%',
        '****-**.[0-3].**-EXT[1-6] %F%',
        '*****-**.[0-3].**-EXT[1-6] %F%',
        '******-**.[0-3].**-EXT[1-6] %F%',
        '****-**.[0-3].**-INT[1-6] %P%',
        '*****-**.[0-3].**-INT[1-6] %P%',
        '******-**.[0-3].**-INT[1-6] %P%',
        '****-**.[0-3].**-EXT[1-6] %P%',
        '*****-**.[0-3].**-EXT[1-6] %P%',
        '******-**.[0-3].**-EXT[1-6] %P%',
      ];
      break;
    default:
      log(
        `Unhandled device type at checkDeviceNameInvalid: ${device.device_type_name_special}`,
        true
      );
      break;
  }

  if (regexes.some(regex => device.device_name.match(regex))) {
    return [];
  }

  return patterns;
}

export function getDeviceById(
  deviceList: TreeDevice[],
  parentId: number,
  childId: number,
  subChildId: number
): TreeDevice | undefined {
  const deviceToReturn = deviceList.filter(device => {
    return (
      device.parent_device_id == parentId &&
      device.child_device_id == childId &&
      device.subchild_device_id == subChildId
    );
  })[0];
  return deviceToReturn;
}

export function getDeviceByName(
  deviceList: TreeDevice[],
  deviceName: string
): TreeDevice | undefined {
  return deviceList.find(device => device.device_name === deviceName);
}

export function getIndexByDeviceKey(
  deviceList: TreeDevice[],
  deviceKey: string
): number {
  return deviceList.findIndex(device => device.deviceKey == deviceKey);
}

export const getDeviceChildrenById = (
  node: TreeDevice,
  deviceList?: TreeDevice[]
): TreeDevice[] => {
  let children: TreeDevice[] = [];
  if (deviceList?.length) {
    if (node.treeLevel === TreeLevel.PARENT) {
      children = deviceList.filter(
        (child: TreeDevice) => child.parent_device_id === node.parent_device_id
      );
    } else if (node.treeLevel === TreeLevel.CHILD) {
      children = deviceList.filter(
        (child: TreeDevice) =>
          child.parent_device_id === node.parent_device_id &&
          child.child_device_id === node.child_device_id
      );
    }
  }
  return children;
};

export function getNewNameErrorMessages(
  allNodeNames: string[],
  node: Entity,
  newName: string
): string[] {
  switch (node?.type) {
    case NodeTypes.HardwareCamera:
      return getCameraNameFieldErrors(allNodeNames, node, newName);
    case NodeTypes.Recorder:
      return getRecorderServerNameFieldError(allNodeNames, node, newName);
    case NodeTypes.Encoder:
      return getEncoderNameFieldErrors(allNodeNames, node, newName);
    case NodeTypes.EncoderCamera:
      return getEncoderCameraNameFieldErrors(allNodeNames, node, newName);
    case NodeTypes.EncoderMetadata:
      return getEncoderMetadataNameFieldErrors(allNodeNames, node, newName);
    default:
      return [];
  }
}

export function getNameErrorMessages(
  allNodeNames: string[],
  node: Entity
): string[] {
  const validationErrors = getNewNameErrorMessages(
    allNodeNames,
    node,
    node.name
  );
  return validationErrors.filter(
    error => error !== getDecommissionedRecorderErrorMessage()
  );
}

export function getAllNames(
  allRecorders: Recorder[],
  recordersMap: RecorderDeviceTuple[]
): string[] {
  const nodeNames = recordersMap
    .flatMap(recorderMap => recorderMap.recorderDevices)
    .flatMap(recorderDevice => getRecorderDeviceNames(recorderDevice));
  return allRecorders.map(recorder => recorder.name).concat(nodeNames);
}

function getRecorderDeviceNames(recorderDevice: RecorderDevice): string[] {
  if (recorderDevice.children && recorderDevice.children.length > 0) {
    const childrenNames: string[] = [];
    recorderDevice.children.forEach(child => {
      if (child) {
        childrenNames.concat(getRecorderDeviceNames(child));
      }
    });
    return childrenNames.concat(recorderDevice.name);
  }
  return [];
}

export function duplicateName(
  allNodeNames: string[],
  newName: string
): boolean {
  return allNodeNames.includes(newName);
}
