import { TreeDevice } from 'src/types';
import { DevicesSliceInterface } from '@stores/slices/isc/devicesSlice';

// expand or collapse entire device nodes in tree, bottom to up
export const expandCollapseAllDeviceNodesAction = (
  state: DevicesSliceInterface,
  expanded: boolean
): void => {
  expandCollapseDeviceNodes(state.subchildDevices, expanded);
  expandCollapseDeviceNodes(state.childDevices, expanded);
  expandCollapseDeviceNodes(state.parentDevices, expanded);
};

export const expandCollapseDeviceNodes = (
  nodes: TreeDevice[],
  expanded: boolean
): void => {
  nodes.forEach((node: TreeDevice) => (node.expanded = expanded));
};
