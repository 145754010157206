import { TreeDevice } from 'src/types';
import { DevicesSliceInterface } from '@stores/slices/isc/devicesSlice';
import { getDeviceChildrenById } from '@utils/helpers';

// search query string in device nodes in tree
// !!important: the order of search SHOULD NOT be changed to determine expand/collapse, bottom to up
export const searchAllDeviceNodesAction = (
  state: DevicesSliceInterface,
  query: string
): void => {
  searchDeviceNodes(query, state.subchildDevices);
  searchDeviceNodes(query, state.childDevices, state.subchildDevices);
  searchDeviceNodes(query, state.parentDevices, state.childDevices);
};

export const searchDeviceNodes = (
  query: string,
  nodes: TreeDevice[],
  childrenNodes?: TreeDevice[]
): void => {
  nodes.forEach((node: TreeDevice) => {
    const isHighlight = query.length
      ? node.device_name.toLowerCase().includes(query.toLowerCase())
      : false;
    node.highlight = isHighlight;

    const children = getDeviceChildrenById(node, childrenNodes);
    const found = children.some(
      (child: TreeDevice) => child.expanded || child.highlight
    );
    if (found) {
      node.expanded = true;
    }
  });
};
