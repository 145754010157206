import React, { ReactElement, useEffect, useState } from 'react';
import { Input } from '@amzn/awsui-components-react';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import { selectGlobalIsLoading } from '@stores/slices/userSlice';
import { searchDeviceNodes } from '@stores/slices/isc/devicesSlice';

const SearchByDeviceNameFilter = (): ReactElement => {
  const isTreeLoading = useAppSelector(selectGlobalIsLoading);
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const search = setTimeout(() => {
      if (searchQuery.length >= 2) {
        dispatch(searchDeviceNodes({ query: searchQuery }));
      }
    }, 1000);

    return (): void => clearTimeout(search);
  }, [searchQuery, dispatch]);

  return (
    <Input
      disabled={isTreeLoading}
      onChange={(e): void => setSearchQuery(e.detail.value)}
      value={searchQuery}
      placeholder="Search..."
      type="search"
    />
  );
};

export default SearchByDeviceNameFilter;
