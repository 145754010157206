import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Header } from '@amzn/awsui-components-react';
import { HowManyPixel } from 'src/components/HowManyPixel';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { selectSelectedSiteCodes } from 'src/stores/slices/userPrefsSlice';
import TopNotification from '@features/isc/TopNotification';
import UserPreferences from '@features/isc/UserPreferences';
import DevPanel from 'src/pages/isc/layout/DevPanel';
import { DeviceTree } from '@features/isc/DeviceTree';
import { DeviceMenu } from '@features/isc/DeviceMenu';
import { getDevicesForSites } from '@features/isc/actions';

export default function MainContent(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedSitesFromState = useAppSelector(selectSelectedSiteCodes);

  useEffect(() => {
    if (selectedSitesFromState?.length) {
      dispatch(getDevicesForSites(selectedSitesFromState));
    }
    return;
  }, [dispatch, selectedSitesFromState]);

  return (
    <>
      <Box>
        <TopNotification />
        <Grid
          gridDefinition={[{ colspan: 2, offset: { xxs: 1 } }, { colspan: 8 }]}>
          <Container header={<Header variant="h2">{t('Actions')}</Header>}>
            <DeviceMenu />
          </Container>
          <Container header={<Header variant="h2">{t('Devices')}</Header>}>
            <DeviceTree key={'tree'} />
          </Container>
        </Grid>
      </Box>
      <HowManyPixel />
      <UserPreferences />
      <DevPanel />
    </>
  );
}
