import { SpaceBetween } from '@amzn/awsui-components-react';
import { IpMismatchNotifications } from '@features/milestones/tree/components/notifications/IpMismatchNotification';
import { selectAllRecorders } from '@stores/slices/milestones/milestonesSlice';
import React, { ReactElement, useEffect, useMemo } from 'react';
import SearchTree from './components/SearchTree';
import {
  RecorderDeviceTuple,
  RecorderServer,
  RecorderServersResponse,
} from 'src/API';
import { useBatchRecorderDevices } from 'src/services/useBatchRecorderDevices';
import { useRecorderServers } from 'src/services/useRecorderServers';
import { getAllNames, log } from 'src/utils/helpers';
import { useSearchParams } from 'react-router-dom';
import { SearchParameters } from '@features/milestones/hooks/useMilestonesNavigate';
import { Entity, Recorder } from '@features/milestones/types/api-types';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import {
  clearAllTreeMessages,
  initData,
  setRecorderIdsContainingIpMismatch,
  setTreeLoading,
} from '@stores/slices/milestones/tree/treeSlice';
import { setGlobalIsLoading } from '@stores/slices/userSlice';
import { doesRecorderDeviceIpMatch, transformRecorderTuple } from './utils';
import {
  addFlashBarMessage,
  clearAllMessages,
  setSingleFlashBarMessage,
} from '@stores/slices/milestones/flashBar/flashBarSlice';
import {
  FETCHING_RECORDER_DEVICES,
  FETCHING_RECORDER_SERVERS,
} from '@features/milestones/recorders/constants';
import {
  ERROR_LOADING_RECORDER_DEVICES,
  ERROR_LOADING_RECORDER_SERVERS,
} from './constants';

export type RecordersForSiteCode = {
  [recorderId: string]: RecorderServer;
};

const SingleSiteTree = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const siteCode = searchParams.get(SearchParameters.Site);
  const allRecorders = useAppSelector(selectAllRecorders);

  log('*** SingleSiteTree(): siteCode=', false, siteCode);

  const {
    isLoading: isLoadingBySiteCode,
    isError: isErrorBySiteCode,
    error: errorBySiteCode,
    recordersResponse,
    refetchRecorderServers,
    removeRecorderServersCache,
  } = useRecorderServers([siteCode!]);

  const recordersForSiteCode: RecordersForSiteCode = useMemo(
    () => processRecorderServers(recordersResponse),
    [recordersResponse]
  );

  const {
    isLoading: isLoadingBatch,
    isError: isErrorBatch,
    error: errorBatch,
    recorderDevices,
    refetchBatchRecorderDevices,
    removeBatchRecorderDeviceQueries,
  } = useBatchRecorderDevices(Object.keys(recordersForSiteCode));

  const invalidateQueryCache = (): void => {
    removeRecorderServersCache();
    refetchRecorderServers();
    removeBatchRecorderDeviceQueries();
    refetchBatchRecorderDevices();
  };

  useEffect(() => {
    dispatch(clearAllTreeMessages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearAllMessages());
    setTimeout(async () => {
      const rootNode = processRecorderDevices(
        siteCode!,
        recorderDevices,
        recordersForSiteCode
      );
      const recorderIdsContainingIpMismatch: string[] = recorderDevices
        .filter(
          tuple =>
            !tuple.recorderDevices.every(device =>
              doesRecorderDeviceIpMatch(device)
            )
        )
        .map(tuple => tuple.recorderId);
      dispatch(
        initData({
          allNames: getAllNames(allRecorders, recorderDevices),
          rootNode: [rootNode],
        })
      );
      dispatch(
        setRecorderIdsContainingIpMismatch(recorderIdsContainingIpMismatch)
      );
    }, 500);
  }, [allRecorders, dispatch, recorderDevices, recordersForSiteCode, siteCode]);

  useEffect(() => {
    dispatch(setTreeLoading(isLoadingBatch && isLoadingBySiteCode));
    dispatch(setGlobalIsLoading(isLoadingBatch && isLoadingBySiteCode));
  }, [dispatch, isLoadingBatch, isLoadingBySiteCode]);

  useEffect(() => {
    if (isErrorBySiteCode || isErrorBatch) {
      dispatch(
        addFlashBarMessage(
          isErrorBySiteCode
            ? ERROR_LOADING_RECORDER_SERVERS
            : ERROR_LOADING_RECORDER_DEVICES
        )
      );
    } else {
      dispatch(clearAllMessages());
    }
  }, [dispatch, isErrorBySiteCode, isErrorBatch, errorBySiteCode, errorBatch]);

  useEffect(() => {
    setTimeout(async () => {
      if (isLoadingBySiteCode || isLoadingBatch) {
        dispatch(
          setSingleFlashBarMessage(
            isLoadingBySiteCode
              ? FETCHING_RECORDER_SERVERS
              : FETCHING_RECORDER_DEVICES
          )
        );
      } else {
        dispatch(clearAllMessages());
      }
    }, 200);
  }, [dispatch, isLoadingBySiteCode, isLoadingBatch]);

  return (
    <SpaceBetween size={'xxs'}>
      <IpMismatchNotifications refetchDevices={invalidateQueryCache} />
      <SearchTree onRename={invalidateQueryCache} />
    </SpaceBetween>
  );
};

const processRecorderServers = (
  recordersResponse: RecorderServersResponse | undefined
): RecordersForSiteCode => {
  const recordersForSiteCode: RecordersForSiteCode = {};
  if (recordersResponse) {
    recordersResponse.recorders.forEach(recorder => {
      recordersForSiteCode[recorder.id] = recorder;
    });
  }
  return recordersForSiteCode;
};

const processRecorderDevices = (
  siteCode: string,
  recorderDevices: RecorderDeviceTuple[],
  recordersForSiteCode: RecordersForSiteCode
): Entity => {
  const rootNode: Entity = {
    children: [] as Entity[],
    id: siteCode!,
    name: siteCode!,
    type: 'Site',
    siteCode: siteCode,
  };

  const addedRecorderIds: string[] = [];

  recorderDevices.forEach(tuple => {
    const currentRecorderServer = recordersForSiteCode[tuple.recorderId];
    if (!currentRecorderServer) return;

    const currentRecorder = {
      ...currentRecorderServer,
      totalDevices: currentRecorderServer.deviceCount,
      region: 'region', // not currently used in tree render
    } as Recorder;

    const currNode = transformRecorderTuple(currentRecorder, tuple);
    rootNode.children?.push(currNode);
    addedRecorderIds.push(tuple.recorderId);
  });

  // account for recorders that do not have children, BE does not return tuple
  const emptyRecorderIds = getEmptyRecorders(
    recordersForSiteCode,
    addedRecorderIds
  );

  emptyRecorderIds.forEach(recorderId =>
    rootNode.children?.push({
      children: [] as Entity[],
      id: recorderId,
      name: recordersForSiteCode[recorderId].name,
      type: 'Recorder',
    } as Entity)
  );

  return rootNode;
};

const getEmptyRecorders = (
  recordersForSiteCode: RecordersForSiteCode,
  addedRecorderIds: string[]
): string[] => {
  return Object.keys(recordersForSiteCode).filter(
    recorderId => !addedRecorderIds.includes(recorderId)
  );
};

export default SingleSiteTree;
