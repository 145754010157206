/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const updateUserPrefs = /* GraphQL */ `mutation UpdateUserPrefs(
  $lastConnected: Float
  $lastSites: String
  $lastUpdated: Float!
  $userId: ID!
) {
  updateUserPrefs(
    lastConnected: $lastConnected
    lastSites: $lastSites
    lastUpdated: $lastUpdated
    userId: $userId
  ) {
    eventsTableLocation
    id
    lastConnected
    lastSites
    lastUpdated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPrefsMutationVariables,
  APITypes.UpdateUserPrefsMutation
>;
export const renameDevices =
  /* GraphQL */ `mutation RenameDevices($devices: [DeviceInput]) {
  renameDevices(devices: $devices)
}
` as GeneratedMutation<
    APITypes.RenameDevicesMutationVariables,
    APITypes.RenameDevicesMutation
  >;
export const linkDevices =
  /* GraphQL */ `mutation LinkDevices($devices: [LinkDeviceInput]) {
  linkDevices(devices: $devices)
}
` as GeneratedMutation<
    APITypes.LinkDevicesMutationVariables,
    APITypes.LinkDevicesMutation
  >;
export const renameRecorderServer =
  /* GraphQL */ `mutation RenameRecorderServer($id: String, $name: String) {
  renameRecorderServer(id: $id, name: $name) {
    status
    device {
      id
      name
      type
      lastName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.RenameRecorderServerMutationVariables,
    APITypes.RenameRecorderServerMutation
  >;
export const renameEncoder =
  /* GraphQL */ `mutation RenameEncoder($id: String, $name: String) {
  renameEncoder(id: $id, name: $name) {
    status
    device {
      id
      name
      type
      lastName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.RenameEncoderMutationVariables,
    APITypes.RenameEncoderMutation
  >;
export const renameEncoderCamera =
  /* GraphQL */ `mutation RenameEncoderCamera($id: String, $name: String) {
  renameEncoderCamera(id: $id, name: $name) {
    status
    device {
      id
      name
      type
      lastName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.RenameEncoderCameraMutationVariables,
    APITypes.RenameEncoderCameraMutation
  >;
export const renameHardwareCamera =
  /* GraphQL */ `mutation RenameHardwareCamera($id: String, $name: String) {
  renameHardwareCamera(id: $id, name: $name) {
    status
    device {
      id
      name
      type
      lastName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.RenameHardwareCameraMutationVariables,
    APITypes.RenameHardwareCameraMutation
  >;
export const renameMetadata =
  /* GraphQL */ `mutation RenameMetadata($id: String, $name: String) {
  renameMetadata(id: $id, name: $name) {
    status
    device {
      id
      name
      type
      lastName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.RenameMetadataMutationVariables,
    APITypes.RenameMetadataMutation
  >;
export const fixDeviceIps =
  /* GraphQL */ `mutation FixDeviceIps($recorderServer: String) {
  fixDeviceIps(recorderServer: $recorderServer) {
    status
    devices {
      id
      name
      type
      lastName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.FixDeviceIpsMutationVariables,
    APITypes.FixDeviceIpsMutation
  >;
