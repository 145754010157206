import { RecorderDevice, RecorderDeviceTuple } from 'src/API';
import {
  DeviceType,
  Entity,
  NodeTypes,
  Recorder,
} from '@features/milestones/types/api-types';

/**
 * Recursively get Entity from RecorderDevice
 */
export function getEntityFromRecorderDevice(
  recorder: Recorder,
  device: RecorderDevice
): Entity {
  const children: Entity[] = [];
  if (!!device.children && device.children.length > 0) {
    device.children.map(child => {
      children.push(
        getEntityFromRecorderDevice(recorder, child as RecorderDevice)
      );
    });
  }
  return {
    id: device.id,
    myRecorderServerId: recorder.id,
    name: device.name,
    type: device.type as DeviceType,
    cameraModel:
      [
        NodeTypes.HardwareCamera,
        NodeTypes.EncoderCamera,
        NodeTypes.EncoderMetadata,
      ].includes(device.type.trim()) && !!device.model
        ? device.model
        : undefined,
    encoderModel:
      device.type === NodeTypes.Encoder && !!device.model
        ? device.model
        : undefined,
    siteCode: recorder.siteCode,
    appServer: device.appServer || undefined,
    channelNumber: device.channelNumber || undefined,
    hardwareEnabled: device.hardwareEnabled || undefined,
    cameraEnabled: device.cameraEnabled || undefined,
    location: device.location || undefined,
    children,
  };
}

export function transformRecorderTuple(
  recorder: Recorder,
  recorderTuple: RecorderDeviceTuple | undefined
): Entity {
  const children = recorderTuple
    ? recorderTuple.recorderDevices.map(device =>
        getEntityFromRecorderDevice(recorder, device)
      )
    : ([] as Entity[]);

  const rootNode = {
    ...recorder,
    children,
  } as Recorder;
  return rootNode;
}

export function doesRecorderDeviceIpMatch(
  device: RecorderDevice | null
): boolean {
  if (!device) return true;
  const childrenDeviceIpMatch = device.children
    ? device.children.every(child => doesRecorderDeviceIpMatch(child))
    : true;
  return device.ipAddressMatch && childrenDeviceIpMatch;
}
