import React, { useState } from 'react';
import TopNotification from './TopNotification';
import { Box, Container } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import ExpandableDevice from 'src/features/isc/ExpandableDevice/ExpandableDevice';
import { log } from 'src/utils/helpers';

function Device(): React.ReactElement {
  const [expanded, setExpanded] = useState(false);
  const [deviceName, setDeviceName] = useState('deviceName');

  const devices = ['device1', 'device2'];
  const iconSize = '1.2em';

  const children = devices.map(device => {
    return (
      <ExpandableDevice
        key={device}
        iconSize={iconSize}
        onDeviceNameChanged={(
          changeDetails
        ): { error: string; success: boolean } => {
          log(`New device name ${changeDetails.newName}`);
          setDeviceName(changeDetails.newName);
          return {
            success: true,
            error: '',
          };
        }}
        deviceName={device}
      />
    );
  });

  return (
    <ExpandableDevice
      iconSize={iconSize}
      expanded={expanded}
      onChangeExpanded={(change): void => {
        log('onchangeHandler');
        setExpanded(change.expanded);
      }}
      onDeviceNameChanged={(
        changeDetails
      ): { error: string; success: boolean } => {
        log(`New device name ${changeDetails.newName}`);
        setDeviceName(changeDetails.newName);
        return {
          success: true,
          error: '',
        };
      }}
      deviceName={deviceName}
      /* eslint-disable */
      children={children}
      /* eslint-enable */
    />
  );
}

export default function DeviceRenameTestContent(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <TopNotification />
        <Box>
          <Container>
            <Device />
          </Container>
        </Box>
      </Box>
    </>
  );
}
